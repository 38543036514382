import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 查询区域点位
export const locationsSelect = data => request(`${XHR_URL}/api/v1/locations`, data, "GET");
// 区域点位 - 导出
export const locationsExport = data => request(`${XHR_URL}/api/v1/locations/export`, data, "GET");


// 新增区域点位
export const locationsInsert = data => request(`${XHR_URL}/api/v1/locations`, data, "POST");
// 修改区域点位
export const locationsUpdate = data => request(`${XHR_URL}/api/v1/locations`, data, "PUT");
// 删除区域点位
export const locationsDelete = data => request(`${XHR_URL}/api/v1/locations`, data, "DELETE");
import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 查询加盟商
export const operatorsSelect = data => request(`${XHR_URL}/api/v1/operators`, data, "GET");
// 新增加盟商
export const operatorsInsert = data => request(`${XHR_URL}/api/v1/operators`, data, "POST");
// 修改加盟商
export const operatorsUpdate = data => request(`${XHR_URL}/api/v1/operators`, data, "PUT");
// 删除加盟商
export const operatorsDelete = data => request(`${XHR_URL}/api/v1/operators`, data, "DELETE");
// 修改密码
export const editPassWord = data => request(`${XHR_URL}/api/v1/operator/password`, data, "PUT");
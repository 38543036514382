<template>
  <div class="m_pagination_content">
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
      background
    >
    </el-pagination>
  </div>
</template>
<script>
import { PAGE_SIZE } from "@/utils/config";
export default {
  name: "mPagination",
  directives: {},
  data() {
    return {
      currentPage: 1,
    };
  },
  props: {
    total: 0,
  },
  computed: {
    pageSize() {
      return PAGE_SIZE;
    },
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    show() {},
    handleCurrentChange(page) {
      this.currentPage = page;
      this.$emit("pageChange", page);
    },
  },
};
</script>
<style lang="less" scoped>
.m_pagination_content {
  text-align: right;
}
</style>

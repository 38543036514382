import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 机器销售统计报表
export const machinesalesReports = data => request(`${XHR_URL}/api/v1/reports/machinesales`, data, "GET");
// 机器销售统计报表导出
export const machinesalesExport = data => request(`${XHR_URL}/api/v1/reports/machinesales/export`, data, "GET");
// 机器销售统计报表统计
export const machinesalesSum = data => request(`${XHR_URL}/api/v1/reports/machinesales/sum`, data, "GET");

// 商品销售统计报表
export const goodssalesReports = data => request(`${XHR_URL}/api/v1/reports/goodssales`, data, "GET");
// 商品销售统计报表导出
export const goodssalesExport = data => request(`${XHR_URL}/api/v1/reports/goodssales/export`, data, "GET");
// 商品销售统计报表统计
export const goodssalesSum = data => request(`${XHR_URL}/api/v1/reports/goodssales/sum`, data, "GET");

// 机器商品销售统计查询
export const machinegoodssalesReports = data => request(`${XHR_URL}/api/v1/reports/machinegoodssales`, data, "GET");
// 机器商品销售统计导出
export const machinegoodssalesExport = data => request(`${XHR_URL}/api/v1/reports/machinegoodssales/export`, data, "GET");
// 机器商品销售统计统计
export const machinegoodssalesSum = data => request(`${XHR_URL}/api/v1/reports/machinegoodssales/sum`, data, "GET");

// 机器退款统计报表
export const machinerefundsReports = data => request(`${XHR_URL}/api/v1/reports/machinerefunds`, data, "GET");
// 机器退款统计报表导出
export const machinerefundsExport = data => request(`${XHR_URL}/api/v1/reports/machinerefunds/export`, data, "GET");
// 机器退款统计报表统计
export const machinerefundsSum = data => request(`${XHR_URL}/api/v1/reports/machinerefunds/sum`, data, "GET");

// 机器退款统计查询
export const machinegoodsrefundsReports = data => request(`${XHR_URL}/api/v1/reports/machinegoodsrefunds`, data, "GET");
// 机器退款统计查询导出
export const machinegoodsrefundsExport = data => request(`${XHR_URL}/api/v1/reports/machinegoodsrefunds/export`, data, "GET");
// 机器退款统计查询统计
export const machinegoodsrefundsSum = data => request(`${XHR_URL}/api/v1/reports/machinegoodsrefunds/sum`, data, "GET");

// 补撤货统计报表
export const machineloadsReports = data => request(`${XHR_URL}/api/v1/reports/machineloads`, data, "GET");
// 补撤货统计报表导出
export const machineloadsExport = data => request(`${XHR_URL}/api/v1/reports/machineloads/export`, data, "GET");
// 补撤货统计报表统计
export const machineloadsSum = data => request(`${XHR_URL}/api/v1/reports/machineloads/sum`, data, "GET");

// 补撤货统计查询
export const machinegoodsloadsReports = data => request(`${XHR_URL}/api/v1/reports/machinegoodsloads`, data, "GET");
// 补撤货统计查询导出
export const machinegoodsloadsExport = data => request(`${XHR_URL}/api/v1/reports/machinegoodsloads/export`, data, "GET");
// 补撤货统计查询统计
export const machinegoodsloadsSum = data => request(`${XHR_URL}/api/v1/reports/machinegoodsloads/sum`, data, "GET");

// 库存统计报表
export const machinestocksReports = data => request(`${XHR_URL}/api/v1/reports/machinestocks`, data, "GET");
// 库存统计报表导出
export const machinestocksExport = data => request(`${XHR_URL}/api/v1/reports/machinestocks/export`, data, "GET");
// 库存统计报表统计
export const machinestocksSum = data => request(`${XHR_URL}/api/v1/reports/machinestocks/sum`, data, "GET");

// 库存统计查询
export const machinegoodsstocksReports = data => request(`${XHR_URL}/api/v1/reports/machinegoodsstocks`, data, "GET");
// 库存统计查询导出
export const machinegoodsstocksExport = data => request(`${XHR_URL}/api/v1/reports/machinegoodsstocks/export`, data, "GET");
// 库存统计查询统计
export const machinegoodsstocksSum = data => request(`${XHR_URL}/api/v1/reports/machinegoodsstocks/sum`, data, "GET");

// 设备故障报表
export const machinefaultsReports = data => request(`${XHR_URL}/api/v1/reports/machinefaults`, data, "GET");
// 设备故障报表导出
export const machinefaultsExport = data => request(`${XHR_URL}/api/v1/reports/machinefaults/export`, data, "GET");
// 设备故障报表统计
export const machinefaultsSum = data => request(`${XHR_URL}/api/v1/reports/machinefaults/sum`, data, "GET");

// 设备故障查询
export const machinefaultitemsReports = data => request(`${XHR_URL}/api/v1/reports/machinefaultitems`, data, "GET");
// 设备故障查询导出
export const machinefaultitemsExport = data => request(`${XHR_URL}/api/v1/reports/machinefaultitems/export`, data, "GET");
// 设备故障查询统计
export const machinefaultitemsSum = data => request(`${XHR_URL}/api/v1/reports/machinefaultitems/sum`, data, "GET");

// 运行时长统计
export const machineruntimesReports = data => request(`${XHR_URL}/api/v1/reports/machineruntimes`, data, "GET");
// 运行时长统计导出
export const machineruntimesExport = data => request(`${XHR_URL}/api/v1/reports/machineruntimes/export`, data, "GET");
// 运行时长统计统计
export const machineruntimesSum = data => request(`${XHR_URL}/api/v1/reports/machineruntimes/sum`, data, "GET");

// 广告节目单
export const adsplayprogs = data => request(`${XHR_URL}/api/v1/ads/progs`, data, "GET");
// 广告播放日志
export const machineplayadsReports = data => request(`${XHR_URL}/api/v1/reports/machineplayads`, data, "GET");
// 广告播放日志
export const machineplayadsExport = data => request(`${XHR_URL}/api/v1/reports/machineplayads/export`, data, "GET");
import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"
// 文件上传地址
export const fileUpLoadUrl = `${XHR_URL}/api/v1/files`
// 登录
export const login = data => request(`${XHR_URL}/api/v1/login`, data, "POST");
// 登出
export const logOut = data => request(`${XHR_URL}/api/v1/login`, data, "DELETE");
// 获取菜单树
export const menutree = data => request(`${XHR_URL}/api/v1/menutree`, data, "GET");
// 获取菜单列表
export const menulist = data => request(`${XHR_URL}/api/v1/menulist`, data, "GET");
// 获取区域列表
export const regions = data => request(`${XHR_URL}/api/v1/regions`, data, "GET");

// 修改密码
export const editPassWord = data => request(`${XHR_URL}/api/v1/password`, data, "PUT");
import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 查询合伙人
export const franchiseesSelect = data => request(`${XHR_URL}/api/v1/franchisees`, data, "GET");
// 新增合伙人
export const franchiseesInsert = data => request(`${XHR_URL}/api/v1/franchisees`, data, "POST");
// 修改合伙人
export const franchiseesUpdate = data => request(`${XHR_URL}/api/v1/franchisees`, data, "PUT");
// 删除合伙人
export const franchiseesDelete = data => request(`${XHR_URL}/api/v1/franchisees`, data, "DELETE");